import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ZZZFreeTips: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Free characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_tips.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero (ZZZ) Tips and Tricks</h1>
          <h2>
            Tips and tricks for Zenless Zone Zero (ZZZ) What to do and not do in
            the game to start strong!
          </h2>
          <p>
            Last updated: <strong>01/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guides assumes you've read our Beginner guide that explains
          various systems you will encounter in the game and instead it will
          focus on giving various tips that will help you navigate and progress
          through the game.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/zenless/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_beginner.jpg"
                alt="Reroll guide"
              />
            }
          />
        </Row>
        <SectionHeader title="General" />
        <StaticImage src="../../../images/zzz/guides/tips_1.webp" alt="Tips" />
        <ul>
          <li>
            Before starting the story you will be able to choose either{' '}
            <strong>Casual or Challenge mode.</strong> Challenge mode doesn't
            give you pre-made characters to use and the monsters you encounter
            there do more damage and have vastly more HP. Overall there's really
            no reason to pick the Challenge mode unless you want to to well,
            challenge yourself as the rewards are exactly the same in both.
          </li>
          <li>
            You can change between Casual and Challenge mode on the story screen
            at any time - so the choice you make initially isn't final.
          </li>
          <li>
            <strong>Hard mode</strong> unlocks after clearing a chapter and
            provides additional rewards.
          </li>
          <li>
            <strong>Focus on the Main Story first</strong> and only start
            completing side missions when you're forced to level up to unlock
            the next Main Story zone.
          </li>
          <li>
            <strong>Make sure to do all the challenges for the stage</strong>.
            Challenges are basically <strong>Stars</strong> for the stages, so
            make sure to do all of them so you do not have to track back and
            revisit the stage again. Missing out on these stars will also mean
            you'll be getting less tokens, which are needed to progress further
            into the story. In different modes they also provide extra materials
            for pulls or character building.
          </li>
          <li>
            Make sure to do the side missions once you're stuck in story. They
            will also provide with tokens used for unlocking next zones as well
            as extra pull resources.
          </li>
          <li>
            Do not forget to visit NPCs such as Coffee shop, The Newspaper stand
            and your helper Bangboo in your base.
          </li>
          <li>
            You can fuse and dismantle character upgrade materials, so in case
            if you need to upgrade a new character, just dismantle the materials
            you got from upper tiers.
          </li>
          <li>
            Do not forget to do your{' '}
            <strong>Battle Pass Daily and Weekly missions</strong>, they provide
            pretty decent materials, especially during the early stages of the
            game.
          </li>
          <li>
            There's a set of weekly rewards you can obtain in Rally commissions,
            as well as Hollow Zone License Pass, so make sure to do enough runs
            to get them all.
          </li>
          <li>
            Always do your dailies as they provide a good amount of Account EXP
            and Polychromes for your account.
          </li>
        </ul>
        <SectionHeader title="Leveling and Battery usage" />
        <StaticImage src="../../../images/zzz/guides/tips_2.webp" alt="Tips" />
        <ul>
          <li>
            <strong>Don't let your Batteries (stamina) overcap</strong>. 240
            Batteries is the maximum amount you can store and regenerate over
            24h.
          </li>
          <li>
            <strong>Decide on what your main team</strong> is going to be as
            early as possible to avoid wasting resources and to be able to
            better focus on what disk drives to farm for.
          </li>
          <li>
            You ideally want to have a Main DPS, a Stunner, and a Supporter in a
            team. Your Main DPS will want to use the most on field time during
            the enemies staggered phase to dish out the most damage basically,
            other than that you want to parry-swap between the Stunner and Main
            dps most of the time.
          </li>
          <li>
            <strong>
              You will eventually need a Main Dps for each element, but it's not
              something you need to worry about early on.
            </strong>{' '}
            Due to how the team building works in ZZZ, you can most often mix
            them with either the same element or same faction to make use of
            their passives.
          </li>
          <li>
            Use your Batteries for farming resources early on. You will need a
            solid bit for upgrading the characters, it can be either EXP for
            them or the weapons, as well as ascension materials for skills or
            talents. <strong>Do no use them on Drive Disk</strong> farming since
            you only want to start focusing on them once you reach account level
            40.
          </li>
          <li>
            <strong>
              In early game level your Main dps to the max level they can be
            </strong>
            , and the remaining characters in your main team can stay 10 levels
            below to save on resources.
          </li>
          <li>
            Do not invest in 3 star W-Engines (Weapons) too much as they are
            just placeholders until you get 4 and 5 star W-Engines.
          </li>
          <li>
            Do not forget to do your weekly boss runs in the Reckless Challenge!
          </li>
        </ul>
        <SectionHeader title="Drive Disks" />
        <StaticImage src="../../../images/zzz/guides/tips_3.webp" alt="Tips" />
        <ul>
          <li>
            <strong>
              Focus on getting your Account level to level 40 as fast as
              possible as the good Drive Disk sets only start appearing then.
            </strong>{' '}
          </li>
          <li>
            No need to force yourself to activate the 4-set bonuses on your Disk
            Drives in early game. You can instead go for two 2-set bonuses as it
            will be a lot easier to get while still providing a good amount of
            damage increase.
          </li>
          <li>
            <strong>Don't upgrade every Drive Disk you get</strong>. The
            materials needed to do that are pretty limited and once you use them
            up.
          </li>
          <li>
            Do not level lower than{' '}
            <StaticImage
              src="../../../images/zzz/icons/rarity_a.png"
              alt="A Rank"
              className="faction-icon"
            />{' '}
            <strong>A-Rank</strong> Drive Disks as they will become obsolete
            really fast.
          </li>
          <li>
            Save the lower tier tuners since they can be converted and used for
            obtaining Drive Disks in the music shop.
          </li>
        </ul>
        <p>To learn more about the Drive Disks, check our other guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks"
            link="/zenless/guides/disk-drives-about"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disk.png"
                alt="Drive Disks"
              />
            }
          />
          <CategoryCard
            title="Drive Disks Stats"
            link="/zenless/guides/disk-drives-stats"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disk2.png"
                alt="Drive Disks Stats"
              />
            }
          />
          <CategoryCard
            title="Drive Disks Sets"
            link="/zenless/disk-drives"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disksets.png"
                alt="Drive Disks Sets"
              />
            }
          />
        </Row>
        <SectionHeader title="Combat" />
        <StaticImage src="../../../images/zzz/guides/tips_4.webp" alt="Tips" />
        <ul>
          <li>
            Stronger enemies have a stagger meter, they will become immobilized
            if you deplete this meter.
          </li>
          <li>
            If you dodge at the last moment of an attack, you’ll activate
            Extreme Evasion - slowing time for a moment and activating a counter
            attack.
          </li>
          <li>
            Try to charge up you EX Special Skill before using them so they get
            their max output possible for damage.
          </li>
          <li>
            Make sure to watch which moves you should parry - sometimes the
            bosses chain multiple attacks into one sequence and those are the
            best ones to parry.
          </li>
          <li>
            Make sure to use your Ultimate skill as soon as its up. There isn't
            any specific reason to keep it unused since it does a solid amount
            of damage on top of stagger.
          </li>
          <li>
            Once you stagger the enemy, a chain attack will be available. Make
            sure to use the Bangboo before your other units! Bangboos are
            available only at the start of the chain.
          </li>
          <li>
            Parry charges are limited, so keep an eye out at your charges or you
            might get a heavy blow which can mess up a run!
          </li>
        </ul>
        <p>
          To help you better understand the Dodge and Parry mechanics, you
          should watch this great video:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="pVO_hC0nWbw" />
          </Col>
        </Row>
        <SectionHeader title="Videos" />
        <p>For more tips and tricks for the game, check the videos below!</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="a0jAHhOKkpg" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="CvyDMlCSdxs" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZFreeTips;

export const Head: React.FC = () => (
  <Seo
    title="Tips and Tricks | Zenless Zone Zero | Prydwen Institute"
    description="Tips and tricks for Zenless Zone Zero (ZZZ) What to do and not do in the game to start strong!"
    game="zzz"
  />
);
